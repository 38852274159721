/* *, .lit-searcher > *, .lit-searcher > ::after, .lit-searcher > ::before {
  box-sizing: content-box !important;
} */

#fs-search-input-vyhladavania {
  outline-color: initial;
  box-shadow: none;
  /* font-size: 13px; */
  outline: 1;
  /* border-color: #e3ebef; */
  /* border-color: #00ec00; */
  border-color: rgb(221, 176, 92);
  height: 26.8px;
  width:13.5em;
  box-shadow: 0;
  z-index: 2;
  /* color: #777695 !important; */
  /* color: blue; */
  /* background-color: rgb(241, 251, 243); */
  /* background-color: rgb(192, 255, 203); */
  background-color:transparent;
  margin-top: 0.91em;
  margin-right: 1em;
  margin-right: 1em;
}

.fs-search-pozadie {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 2px;
}
.fs-search-ikonka-inputu {
  margin: 5px !important;
  top: 2px !important;
}

/*.App {
  background-color:  blue;
  padding: 5px;
}*/

.fs-search-vysledky {
  max-width: 675px;
  min-width: 430px;
  position: absolute;
  background-color: #fff;
  margin-top: 3px;
  margin-left: -4.5px;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  z-index: 10000;
}

/* .mb-3, .my-3 {
  margin-bottom: 0px !important;
} */

.fs-search-vysledky-odkaz:hover {
  text-decoration: none !important;
}

.fs-search-vysledky-riadok {
  list-style-position: inside;
  /*border-bottom: 1px dotted silver;*/
  width:580px;
  /* height: 38px; */
  cursor: pointer;
  /* display: flex; */
  /* padding: 9px 14px 7px 20px !important; */
  background-color: transparent !important;
}
.fs-search-vysledky-riadok span {
  vertical-align: middle !important;
}

.fs-search-vysledky-cely-riadok :hover {
  background: rgba(232,232,232,255) !important;
}

.fs-search-vysledky-nazov {
  color: black;
  font-weight: 700;
  position: relative;
  /* top: 10px; */
  max-width: 70%;
  display: inline-block;
}

.fs-search-vysledky-ticker{
  /* margin-left: 10px; */
  color: black;
  position: relative;
  top: 10px;
}

.fs-search-vysledky-skupina {
  /* top: 10px; */
  position: relative;
  color: grey;
  float: right;
  /*display: inline-block;
  color: #fff;
  text-align: center;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  padding: 0px 7px!important;
  border-radius: 8px;
  top: .1px!important;
  position: relative;
  height: -webkit-fill-available;*/
}

.fs-search-vysledky-typ {
  display: inline-block;
  color: #fff;
  text-align: center;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  padding: 0px 7px!important;
  border-radius: 8px;
  top: .1px!important;
  position: relative;
  height: -webkit-fill-available;
}

#fs-search-input-vyhladavania::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    black !important;
}
#fs-search-input-vyhladavania:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    black !important;
 opacity:  1;
}
#fs-search-input-vyhladavania::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    black !important;
 opacity:  1;
}
#fs-search-input-vyhladavania:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    black !important;
}
#fs-search-input-vyhladavania::-ms-input-placeholder { /* Microsoft Edge */
 color:    black !important;
}
#fs-search-input-vyhladavania::placeholder { /* Most modern browsers support this now. */
 color:    #777695 !important;
}

@media only screen and (max-width: 766px) {
  .fs-search-vysledky {
    width: 100% !important;
    max-width: 100% !important;
    min-width: unset;
    position: absolute;
    background-color: #fff;
    margin-top: 3px;
    margin-left: -4.5px;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    z-index: 10000;
  }
  .fs-search-vysledky-riadok {
    width: unset;
  }

  #fs-seaerch-divko-vysledkov {
    display: block;
    position: absolute;
    left: 0px;
    width: 100% !important;
    max-width: 100% !important;
  }

  
}

@media only screen and (max-width: 450px) {
  .fs-search-vysledky-sekcia-tickeru {
    display: grid;
  }

  .fs-search-vysledky-nazov {
    display: contents;
  }

  .fs-search-vysledky-ticker {
    text-align: left;
  }

  .fs-search-vysledky-sekcia-marketu {
    display: flex;
  } 
}